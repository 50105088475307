import { HttpClient, HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';
import type { Observable } from 'rxjs';

import type { HttpRequestOptions } from '../types';

export abstract class RestClient<T = void> {
  protected pk = 'Id';
  protected http = inject(HttpClient);

  url = '';

  constructor(public apiUrl: string) {}

  /**
   * Get the full entity uri
   *
   * ${apiUrl}/${entityUrl}
   */
  get uri(): string {
    return `${this.apiUrl}/${this.url}`;
  }

  /**
   * GET all entities
   *
   * Used to get all target entities
   */
  all<TAll = T>(options?: HttpRequestOptions): Observable<TAll[]> {
    return this.http.get<TAll[]>(this.uri, options);
  }

  /**
   * POST a new entity
   *
   * Used to send the request to create new entity
   */
  create<T, K = T>(model: T, options?: HttpRequestOptions): Observable<K> {
    return this.http.post<K>(`${this.uri}`, model, options);
  }

  /**
   * DELETE an entity
   *
   * Used to send request to delete an entity
   */
  delete(id: number | string, options?: HttpRequestOptions) {
    return this.http.delete<T>(`${this.uri}/${id}`, options);
  }

  /**
   * GET an entity
   *
   * Used to get a single entity
   */
  get(id: number | string, options?: HttpRequestOptions): Observable<T> {
    return this.http.get<T>(`${this.uri}/${id}`, options);
  }

  /**
   * PUT ?? POST an entity
   *
   * Used to send request to update an entity
   */
  save<T, K = T>(model: T, options?: HttpRequestOptions): Observable<K> {
    return model[this.pk as keyof typeof model]
      ? this.update(model, options)
      : this.create(model, options);
  }

  /**
   * PUT an entity
   *
   * Used to send request to update an entity
   */
  update<T, K = T>(model: T, options?: HttpRequestOptions): Observable<K> {
    return this.http.put<K>(
      `${this.uri}/${model[this.pk as keyof typeof model]}`,
      model,
      options
    );
  }

  protected getParams(options?: HttpRequestOptions): HttpParams | undefined {
    let params: HttpParams | undefined;

    if (options?.params) {
      if (options.params instanceof HttpParams) {
        params = options.params;
      } else {
        params = new HttpParams({
          fromObject: options.params,
        });
      }
    }

    return params;
  }
}
