import { patch } from '@ngxs/store/operators';

import type { ErrorResult, OperationStatus } from '@cosmos/types-common';

import type { StateOperator } from '../../local-state';
import { ifNull, safePatch } from '../../ngxs-utils';

export function setOperationInProgress(
  canShowPreviousResults = false
): StateOperator<OperationStatus> {
  const value: Partial<OperationStatus> = {
    success: false,
    inProgress: true,
    error: null,
  };
  if (!canShowPreviousResults) {
    value.lastResultLoadedTime = null;
  }
  return safePatch<OperationStatus>(value);
}

// export function setOperationInProgress() {
//   return ifNull<OperationStatus>(
//     {
//       success: false,
//       inProgress: true,
//       error: null,
//     },
//     patch<OperationStatus>({ inProgress: true })
//   );
// }

export function setOperationSuccess() {
  return safePatch<OperationStatus>({
    success: true,
    inProgress: false,
    error: null,
    lastResultLoadedTime: new Date().getTime(),
  });
}

export function setOperationCancelled() {
  return ifNull<OperationStatus>(
    {
      success: false,
      inProgress: false,
      error: null,
      lastResultLoadedTime: null,
    },
    patch<OperationStatus>({ inProgress: false })
  );
}

// export function setOperationCancelled() {
//   return safePatch<OperationStatus>({
//     success: false,
//     inProgress: false,
//     error: null,
//   });
// }

export function setOperationError(error: ErrorResult) {
  return safePatch<OperationStatus>({
    success: false,
    inProgress: false,
    error: error,
    lastResultLoadedTime: null,
  });
}
