import { Injectable } from '@angular/core';
import { Action, State, type StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { syncLoadProgress } from '@cosmos/state';
import type { ModelWithLoadingStatus } from '@cosmos/types-common';
import type { Inventory } from '@smartlink/models';
import { ProductsService as SmartlinkProductsService } from '@smartlink/products/data-access-products';

import { ProductInventoryActions } from '../actions';

export interface InventoryEntity {
  inventory: Inventory | null;
}

export interface ProductInventoryStateModel
  extends InventoryEntity,
    ModelWithLoadingStatus {}

type LocalStateContext = StateContext<ProductInventoryStateModel>;

const defaultState = (): ProductInventoryStateModel => ({
  inventory: null,
});

@State<ProductInventoryStateModel>({
  name: 'productInventory',
  defaults: defaultState(),
})
@Injectable()
export class ProductInventoryState {
  constructor(
    private readonly _smartlinkProductsService: SmartlinkProductsService
  ) {}

  @Action(ProductInventoryActions.Get, { cancelUncompleted: true })
  private _getProductInventory(
    ctx: LocalStateContext,
    { id }: ProductInventoryActions.Get
  ) {
    return this._smartlinkProductsService.getProductInventory(id).pipe(
      catchError((error) => {
        ngDevMode &&
          console.log(`Unable to load inventory for the product ${id}`, error);

        // in case of an error we return null so that we can display a generic error message on UI
        return of(null);
      }),
      tap((productInventory) => {
        ctx.patchState({
          inventory: productInventory,
        });
      }),
      syncLoadProgress(ctx)
    );
  }

  @Action(ProductInventoryActions.Clear)
  private _clear(ctx: LocalStateContext) {
    ctx.patchState({
      inventory: null,
    });
  }

  @Action(ProductInventoryActions.ResetLoading)
  private _resetLoading(ctx: LocalStateContext) {
    ctx.patchState({
      loading: undefined,
    });
  }
}
