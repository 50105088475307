import { HttpParams } from '@angular/common/http';

import type { HttpRequestOptions } from '../types';

export function getHttpParams(
  options?: HttpRequestOptions
): HttpParams | undefined {
  let params: HttpParams | undefined;

  if (options?.params) {
    if (options.params instanceof HttpParams) {
      params = options.params;
    } else {
      params = new HttpParams({
        fromObject: options.params,
      });
    }
  }

  return params;
}
