import type { StateContext } from '@ngxs/store';

import type { ModelWithLoadingStatus } from '@cosmos/types-common';

import {
  syncOperationProgress,
  type SyncOperationOptions,
} from '../operation-status';

export function syncLoadProgress<TStateModel extends ModelWithLoadingStatus>(
  ctx: StateContext<TStateModel>,
  options?: Partial<SyncOperationOptions>
) {
  return syncOperationProgress<TStateModel>(ctx, 'loading', options);
}
