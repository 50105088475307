import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ConfigService } from '@cosmos/config';
import {
  RestClient as BaseRestClient,
  getHttpParams,
  type HttpRequestOptions,
} from '@cosmos/util-http';
import { SearchCriteria, type SearchResult } from '@smartlink/types-search';

@Injectable()
export abstract class SmartlinkRestClient<T = any> extends BaseRestClient<T> {
  private _lastQueryIdReceived = -1;
  private _queryId = 0;

  constructor(configService: ConfigService) {
    super(configService.get('smartlinkApiUrl'));
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  query<TResult>(
    criteria: SearchCriteria = new SearchCriteria(),
    options?: HttpRequestOptions
  ): Observable<SearchResult<TResult>> {
    let params = getHttpParams(options) || new HttpParams();

    const criteriaKeys = Object.keys(criteria) as Array<keyof SearchCriteria>;
    criteriaKeys.forEach((key: keyof SearchCriteria) => {
      const criteriaValue: string | number | boolean | undefined =
        criteria[key];

      if (criteriaValue === undefined) {
        return;
      }

      const value: string | number | boolean =
        typeof criteriaValue === 'object'
          ? JSON.stringify(criteriaValue)
          : criteriaValue;

      params = params.append(key, `${value}`);
    });

    options = { ...options, params };

    return new Observable((subscriber) => {
      const queryId = this._queryId++;

      this.http
        .get<SearchResult<TResult>>(`${this.uri}/search.json`, options)
        .pipe(catchError((err) => this._searchServiceError(err, queryId)))
        .subscribe((res) => {
          subscriber.next(this._searchServiceResponse(res, queryId));
          subscriber.complete();
        });
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------
  private _searchServiceResponse = <TResult>(
    res: SearchResult<TResult>,
    queryId: number
  ) => {
    if (queryId < this._lastQueryIdReceived) {
      // Outdated answer
      return undefined;
    }

    this._lastQueryIdReceived = queryId;

    return res;
  };

  private _searchServiceError = (
    err: any,
    queryId: number
  ): Observable<never> => {
    if (queryId < this._lastQueryIdReceived) {
      // Outdated answer
      return of();
    }

    this._lastQueryIdReceived = queryId;

    // if (this._currentNbQueries === 0) this.emit('searchQueueEmpty');
    return throwError(() => err);
  };
}
