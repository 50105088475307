import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { HttpRequestOptions } from '@cosmos/util-http';
import { SmartlinkRestClient } from '@smartlink/data-access-smartlink-rest-client';
import type {
  Inventory,
  KeywordSuggestions,
  ProductConfigure,
  ProductMatrix,
  ProductOfTheDayAd,
  SmartlinkProduct,
  VendorCharge,
} from '@smartlink/models';

@Injectable({
  providedIn: 'root',
})
export class ProductsService extends SmartlinkRestClient<SmartlinkProduct> {
  override url = 'products';

  // Override get to use v2 API for pricing
  override get(
    id: number | string,
    options?: HttpRequestOptions
  ): Observable<SmartlinkProduct> {
    return this.http.get<SmartlinkProduct>(
      `${this.uri.replace('v1', 'v2')}/${id}`,
      options
    );
  }

  getProductMedia(id: number, params?: { type: string }): Observable<any> {
    return this.http.get(`${this.uri}/${id}/media`, { params });
  }

  getKeywordSuggestions(params?: any): Observable<KeywordSuggestions[]> {
    return this.http.get<KeywordSuggestions[]>(`${this.uri}/keywords`, {
      params,
    });
  }

  getProductInventory(id: number, params?: any) {
    return this.http.get<Inventory>(`${this.uri}/${id}/inventory`, { params });
  }

  getProductCharges(
    id: number | string,
    options?: HttpRequestOptions
  ): Observable<VendorCharge[]> {
    return this.http.get<VendorCharge[]>(`${this.uri}/${id}/charges`, options);
  }

  getProductMatrix(
    id: number,
    params?: Record<string, string | number>
  ): Observable<ProductMatrix> {
    return this.http.get<ProductMatrix>(`${this.uri}/${id}/matrix`, { params });
  }

  getProductConfigure(
    id: number,
    params?: Record<string, string | number>
  ): Observable<ProductConfigure> {
    return this.http.get<ProductConfigure>(`${this.uri}/${id}/configure`, {
      params,
    });
  }

  getPOTDAd(): Observable<ProductOfTheDayAd> {
    return this.http.get<ProductOfTheDayAd>(`${this.uri}/daily`);
  }

  getCategoryPOTDAd(params?: { page: string }): Observable<ProductOfTheDayAd> {
    return this.http.get<ProductOfTheDayAd>(`${this.uri}/daily`, { params });
  }
}
