/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { StateOperator } from '@ngxs/store';
import {
  iif,
  isStateOperator,
  patch,
  type ExistingState,
  type NoInfer,
  type ɵPatchSpec,
} from '@ngxs/store/operators';
import { isArrayLike } from 'lodash-es';

export function ifNull<T>(
  operatorOrValue: NoInfer<T> | StateOperator<T>,
  operatorOrValueIfExists?: NoInfer<T> | StateOperator<T>
) {
  return iif<T>((val) => !val, operatorOrValue, operatorOrValueIfExists);
}

export function safePatch<T extends object>(
  patchSpec: NoInfer<ɵPatchSpec<T>>
): StateOperator<T> {
  const patcher = patch(patchSpec as ɵPatchSpec<T>) as unknown as StateOperator<
    Readonly<NonNullable<T>>
  >;
  return function patchSafely(existing: ExistingState<T>): T {
    return patcher(existing ?? ({} as ExistingState<Readonly<NonNullable<T>>>));
  };
}

export function mergeState<T extends object>(
  mergeSpec: DeepMergeSpec<T>
): StateOperator<T> {
  const patchSpec = convertToPatchSpec<T>(mergeSpec, safePatch);
  const patcher = safePatch<T>(patchSpec);
  return function mergeState(existing: ExistingState<T>): T {
    return patcher(existing ?? <ExistingState<T>>{});
  };

  function convertToPatchSpec<T>(
    spec: DeepMergeSpec<T>,
    safePatchOperator: typeof safePatch
  ): NoInfer<ɵPatchSpec<T>> {
    return Object.fromEntries(
      Object.entries(spec || {}).map(([key, value]) => {
        const newValue = convertToOperator(value, (innerSpec) => {
          const innerPatchSpec: ɵPatchSpec<T> = convertToPatchSpec(
            innerSpec,
            safePatchOperator
          );
          return safePatchOperator(innerPatchSpec);
        });
        return [key, newValue];
      })
    ) as NoInfer<ɵPatchSpec<T>>;
  }

  function convertToOperator<T>(
    spec: StateOperator<T> | DeepMergeSpec<T>,
    op: (patchSpec: any) => StateOperator<T>
  ) {
    if (isStateOperator<T>(spec as any)) {
      return spec;
    }
    if (isArrayLike(spec)) {
      return () => spec;
    }
    if (typeof spec === 'object') {
      return op(spec);
    }
    return () => spec;
  }
}

export type DeepMergeSpec<T> = T extends DeepMergePrimitive | DeepMergeNully
  ? T
  : T extends Array<infer V>
    ? DeepMergeArray<V>
    : DeepMergeObject<T>;

type DeepMergePrimitive = boolean | string | number | symbol | bigint;
type DeepMergeNully = undefined | null;
type DeepMergeArray<T> = StateOperator<NonNullable<Array<T>>>;
type DeepMergeObject<T> = {
  readonly [K in keyof T]?:
    | DeepMergeSpec<T[K]>
    | StateOperator<NonNullable<T[K]>>;
};

export function setOrRemoveProp<T extends object, TKey extends keyof T>(
  prop: TKey,
  value: T[TKey]
): StateOperator<Omit<T, TKey>> | StateOperator<T> {
  if (!value) {
    return deleteProp<T, TKey>(prop);
  }
  return safePatch<T>({ [prop]: value } as any);
}

export function deleteProp<T extends object, TKey extends keyof T>(
  prop: TKey
): StateOperator<Omit<T, TKey>> {
  return (obj: any) => {
    if (obj && Object.prototype.hasOwnProperty.call(obj, prop)) {
      const { [prop]: removed, ...remaining } = obj;
      return remaining;
    }
    return obj;
  };
}
