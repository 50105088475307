import { Injectable, type OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import type { AuthSession } from '@asi/auth/types-auth';

const EXPIRATION_OFFSET_MS = 2 * 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class AuthTokenService implements OnDestroy {
  private readonly _session$ = new BehaviorSubject<AuthSession | null>(null);

  get isExpired() {
    const now = Date.now();
    const expirationDate = this._session$.value?.expires_at;

    return expirationDate != null
      ? now >= expirationDate - EXPIRATION_OFFSET_MS
      : true;
  }

  get refreshToken(): string {
    return this._session$.value?.refresh_token ?? '';
  }

  set token(token: string) {
    const session = this._session$.value;

    session &&
      this._session$.next({
        ...session,
        access_token: token,
      });
  }

  get token(): string {
    return this._session$.value?.access_token ?? '';
  }

  ngOnDestroy(): void {
    this._session$.complete();
  }

  getToken() {
    return this._session$.pipe(map((session) => session?.access_token));
  }

  reset(session: AuthSession | null = null) {
    this._session$.next(session);
  }
}
