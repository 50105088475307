import type { StateContext, StateOperator } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { isObject } from 'lodash-es';
import { Observable } from 'rxjs';

import type { OperationStatus } from '@cosmos/types-common';

import {
  setOperationCancelled,
  setOperationError,
  setOperationInProgress,
  setOperationSuccess,
} from './operation-status.operators';

export interface SyncOperationOptions {
  loadCompletesOn: 'FirstEmit' | 'StreamCompleted';
  errorMessage?: string;
  getErrorMessage?(error: Error): string;
  canShowPreviousResults?: boolean;
}

export type ModelPropToSync<TStateModel> =
  | keyof TStateModel
  | ((
      statusUpdate: StateOperator<OperationStatus>
    ) => StateOperator<TStateModel>);

export function syncOperationProgress<TStateModel>(
  ctx: StateContext<TStateModel>,
  modelPropToSync: ModelPropToSync<TStateModel>,
  options?: Partial<SyncOperationOptions>
) {
  const _options: SyncOperationOptions = {
    loadCompletesOn: 'FirstEmit',
    ...(options || {}),
  };
  function patchStatus(statusUpdate: StateOperator<OperationStatus>) {
    if (typeof modelPropToSync === 'function') {
      return modelPropToSync(statusUpdate);
    }
    // TODO Mark as discussed in Teams I'm leaving that to you:
    // https://github.com/ngxs/store/commit/8834f50e821c05f6d7329dd87d0a1273d65c9d7d
    return patch({
      [modelPropToSync]: statusUpdate,
    }) as unknown as StateOperator<TStateModel>;
  }
  return function <T>(source: Observable<T>): Observable<T> {
    return new Observable((subscriber) => {
      ctx.setState(
        patchStatus(setOperationInProgress(options?.canShowPreviousResults))
      );
      const subscription = source.subscribe({
        next(value) {
          if (_options.loadCompletesOn === 'FirstEmit') {
            ctx.setState(patchStatus(setOperationSuccess()));
          }
          subscriber.next(value);
        },
        error(error) {
          const errorMessage =
            _options.errorMessage ||
            (_options.getErrorMessage && _options.getErrorMessage(error)) ||
            (error && error.message);
          ctx.setState(
            patchStatus(
              setOperationError({
                message: errorMessage,
                devErrorMessage: isObject(error)
                  ? JSON.stringify(error)
                  : error,
              })
            )
          );
          subscriber.error(error);
        },
        complete() {
          if (_options.loadCompletesOn === 'StreamCompleted') {
            ctx.setState(patchStatus(setOperationSuccess()));
          }
          subscriber.complete();
        },
      });
      return () => {
        ctx.setState(patchStatus(setOperationCancelled()));
        subscription.unsubscribe();
      };
    });
  };
}
