const ACTION_SCOPE = '[Product Inventory]';

export namespace ProductInventoryActions {
  export class Get {
    static readonly type = `${ACTION_SCOPE} Get`;

    constructor(public readonly id: number) {}
  }

  export class Clear {
    static readonly type = `${ACTION_SCOPE} Clear`;
  }

  export class ResetLoading {
    static readonly type = `${ACTION_SCOPE} Reset Loading`;
  }
}
